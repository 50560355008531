const cookies = {
    get: function (name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    set: function (name, value, days) {
        let expires = "";
        let security = "; SameSite=Strict";

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

            expires = "; expires=" + date.toUTCString();
        }
        if (window.location.protocol == "https:") {
            security += "; Secure"
        }

        document.cookie = name + "=" + (value || "") + expires + security + "; path=/";
    }
}

export default cookies
